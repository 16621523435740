import React from 'react'
import styled from 'styled-components'
import {SignInLayout, Card, CardHeader, CardActionButton} from '../components/SigninLayout'
import {Icon} from 'startlibs/lib/components'
import {FormattedMessage} from "react-intl";
import {getColor} from 'startlibs/lib/lib'
import { Link } from 'react-router-dom';

const AlreadyActivatedPage = styled(SignInLayout) `
  color: rgba(0,0,0,0.5);
  h2 {
    margin-bottom: 0.5rem;
    color: ${getColor('main')};
    font-size: 22px;
  }
  ${Icon} {
    font-size: 90px;
    margin-bottom: 0.75rem;
    display: block;
  }
`

export class AlreadyActivated extends React.PureComponent {

  render() {
    const {system} = this.props
    return <AlreadyActivatedPage system={system}>
      <Card centralizedContent>
          <h2><FormattedMessage
            defaultMessage="This user account has already been activated"
            description="Already activated page title"
          /></h2>
          <p><FormattedMessage
            defaultMessage="Please sign in using the new password you created during activation."
            description="Already activated page message"
          /></p>
          <CardActionButton.a highlight href={`../login${system.systemId ? `?systemId=${system.systemId}` : ''}`}>{system.labels.systemGoTo}</CardActionButton.a>
      </Card>
    </AlreadyActivatedPage>
  }

}
