import React from 'react'
import styled from 'styled-components'
import {SignInLayout, Card, CardHeader, CardActionButton} from '../components/SigninLayout'
import {Button} from 'startlibs/lib/components'
import {FormattedMessage} from "react-intl";


// This screen will have button for user to click and perfome the activation.
// This prevents the activation link from being sent in e-mail - where it was being clicked by oulook or antivirus pre-fetching;
export class Activate extends React.PureComponent {

  render() {
    const {system,token} = this.props
    // get param key from url
    const key = new URLSearchParams(window.location.search).get('key')
    
    return <SignInLayout system={system}>
      <Card centralizedContent>
        {
          <CardHeader>
            <h1><FormattedMessage
              defaultMessage="Activate your account"
              description="Activate account title"
            /></h1>
            <p><FormattedMessage
              defaultMessage="Please click the button below to activate your account."
              description="Click button to activate account message"
            /></p>
          </CardHeader>
        }
        <CardActionButton.a href={`/api/activate?key=${key}&systemId=${system.systemId}`} highlight>
          <FormattedMessage
            defaultMessage="Activate account"
            description="Activate account button"
          />
        </CardActionButton.a>
      </Card>
    </SignInLayout>
  }

}
