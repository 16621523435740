import React from 'react'
import {withRouter} from 'react-router-dom'
import {Button, Icon} from 'startlibs/lib/components'
import {Errors, Field, Form, SimpleCheckbox, TextInput, withForm, WithForm} from 'startlibs/lib/form'
import {formFetch, postFetcher} from 'startlibs'
import {withToggles} from 'startlibs/lib/hocs'
import {loginWithJwt, logout, setNotifications} from '../reducers'
import {buildValidation, required, responseFailure} from '../lib/validation'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {Card, CardHeader, SignInLayout} from '../components/SigninLayout'
import {getColor} from 'startlibs/lib/lib'
import {PasswordInput} from '../components/PasswordInput'
import {FormattedMessage, injectIntl} from "react-intl";

const SignInCard = styled(Card)`
  max-width: 35rem;
  margin: auto;
  ${CardHeader} {
    text-align: center;
  }
`

const AccountEmail = styled.div `
  font-size:14px;
  font-weight: 600;
  background: rgb(240 240 240);
  padding: 0.5rem 1.25rem;
  display: inline-block;
  margin-bottom: 0.5rem;
  border-radius: 100px;
`

const ResendCodeMessage = styled.div`
  color: rgba(0,0,0,0.5);
  margin-top: 1rem;
  p {
    margin-bottom: 0.25rem;
  }
  .link {
    font-weight: 600;
  }
  .detail {
    font-size: 12px;
  }
  .loading {
    color: ${getColor('main')};
    font-weight: 600;
  }
  .success {
    color: ${getColor('success')};
  }
`

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
  ${Button} {
    min-width: 14rem;
  }
`
const LinkContainer = styled.div `
  margin-top: 1rem;
  text-align: center;
  color: rgba(0,0,0,0.5);
  a {
    color: inherit;
    text-decoration: underline;
  }
`
const SuccessFeedback = styled.div `
    padding: 1rem 3.5rem 1rem 1rem;
    border-radius: 6px;
    margin: 1rem auto;
    position:relative;
    text-align: left;
    background: ${getColor('success')};
    color: white;
    max-width: 35rem;
`
const CloseIcon = styled(Icon)`
  font-weight: 600;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  width: 20px;
  height: 20px;
  line-height: 20px;
  &:hover {
    background: rgba(0,0,0,0.25);
  }
`

@injectIntl
@withRouter
@withToggles('loading', 'sendingRecovery', 'sentRecovery')
@withForm(loginWithJwt)
@connect(undefined, {logout,setNotifications})
export class JwtAuthentication extends React.Component {

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 403) {
      this.props.passwordExpired(response.params.key)
    } else {
      responseFailure(
        ({detail, message, status}) =>
          ((status === 401) && {'password': [this.props.intl.formatMessage({
              defaultMessage: `Wrong password. Try again or click Forgot password to reset it.`,
              description: 'Wrong password error message'
            })]})
      )(...args)
    }
  }

  logout = (expired) => {
    this.props.logout()
    this.props.history.push(`/?systemId=${this.props.system.systemId}&username=${this.props.username}`, {
      loginKey: Date.now(),
      expiredSession: expired
    })
  }

  onSuccess = (_, {id_token,username,idToken,preAuthToken}) => {
    if (username) {
      this.props.needsRegistration(idToken,username)
    } else if (preAuthToken) {
      this.props.verify2fa.open(preAuthToken)
    } else  {
      this.props.loading.open()
      window.location = this.props.system.systemWebHooks.systemAuthWebHook + id_token
    }
    // this.props.loading.open()
    // this.props.concludeLogin(id_token)
  }



  sendRecovery = (autoLogin) => {
    this.props.sentRecovery.close()
    this.props.sendingRecovery.open()
    postFetcher('/pasapi/account/reset-password/init', {username:this.props.parsedJwt.sub,systemId:this.props.system.systemId, autoLogin: autoLogin == null ? true : autoLogin})
      .then(() => {
        this.props.sentRecovery.open()
        this.props.sendingRecovery.close()
      })
      .catch(({errorResponse:{status}}) => {
        if (status===404)
          this.props.setNotifications([{status:'error',value:this.props.intl.formatMessage({
              defaultMessage:'E-mail not registered yet.',
              description:'E-mail not registered yet error message'
            })}])
      })
  }

  render() {
    const {intl, system, form, loading, jwt, sentRecovery, sendingRecovery, parsedJwt, autoLogin} = this.props
    const preValidation = buildValidation({
      password: [required(intl)]
    })
    const email = parsedJwt.sub
    const loginInfo = JSON.parse(parsedJwt.loginInfo)
    return (
      <SignInLayout system={system}>
        <SignInCard>
          <CardHeader>
            <h1>{system.systemName}</h1>
            <p><FormattedMessage
              defaultMessage="Enter the password linked to the account:"
              description="JWT Authentication, instructions"
            /></p>
            <AccountEmail>{email}</AccountEmail>
          </CardHeader>
          <Form
            alwaysSave
            preValidation={preValidation}
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            form={form}
            jwt={jwt}
          >
            <input type="text" name="username" value={email} autoComplete="username email" style={{'display': 'none'}} />
          <Field label={intl.formatMessage({
              defaultMessage: "Account password",
              description: "JWT Authentication, password field label"
            })}>
            {this.props.sendingRecovery.isOpen ?
            <span
              className="sending-link"
            ><FormattedMessage
              defaultMessage="Sending recovery link..."
              description="JWT Authentication, sending recovery link message"
            /></span>
            :
            <a
              className={(Object.keys(form.errors).length ? 'has-error ' : '') + 'password-link'}
              onClick={() => this.sendRecovery(autoLogin)}
              tabIndex={2}
            ><FormattedMessage
              defaultMessage="Forgot password?"
              description="JWT Authentication, forgot password link"
            /></a>
            }
            <PasswordInput
              autoFocus
              autoComplete="current-password"
              type="password"
              tabIndex={1}
              withoutField
              placeholder={intl.formatMessage({
                defaultMessage: "Enter your password",
                description: "JWT Authentication, password input placeholder"
              })}
              form={form}
              path="password"
            />
          </Field>
            <Errors form={form}/>
            <ButtonsContainer>
              <Button highlight isLoading={form.isLoading || loading.isOpen} tabIndex={1} type="submit"><FormattedMessage
                defaultMessage="Access"
                description="JWT Authentication, submit button"
              /></Button>
            </ButtonsContainer>
            <LinkContainer><FormattedMessage
              defaultMessage="Not your account? <a>Return</a>"
              description="JWT Authentication, not your account link"
              values={{
                a: (...chunks) => <a href={loginInfo.url}>{chunks}</a>
              }}
            /></LinkContainer>
          </Form>
        </SignInCard>
        {this.props.sentRecovery.isOpen && <SuccessFeedback><FormattedMessage
          defaultMessage="Recovery link successfully sent to your e-mail."
          description="JWT Authentication, recovery link sent message"
          /><CloseIcon onClick={() => this.props.sentRecovery.close()} icon="x"/></SuccessFeedback>}
      </SignInLayout>
    )
  }
}
