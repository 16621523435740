import React from 'react'
import styled from 'styled-components'
import {TextInput, WithForm, Errors} from 'startlibs/lib/form'
import {withToggles} from 'startlibs/lib/hocs'
import {formFetch} from 'startlibs'
import {Link, Button} from 'startlibs/lib/components'
import {
  Card,
  CardHeader,
  SignInLayout,
  CardActionButton,
  AdditionalActionButtons,
  AdditionalActionText
} from '../components/SigninLayout'
import {buildValidation, emailValidation, responseFailure} from '../lib/validation'
import {SupportMessage} from '../components/SupportMessage'
import {FormattedMessage, injectIntl} from "react-intl";

const LinkContainer = styled.div `
  margin-top: 1rem;
  text-align: center;
`

const onFailure = responseFailure(({message, title, status}) =>
  (status === 404 && {
    username: [<React.Fragment><FormattedMessage
      defaultMessage="No account found using this username."
      description="Password recovery error message"
    /></React.Fragment>]
  })
)

export const PasswordRecovery = injectIntl(withToggles('successFeedback')(({system, intl, successFeedback}) =>
  <WithForm
    action={formFetch}
    formProperties={{systemId:system.systemId}}
    url="/pasapi/account/reset-password/init"
    onFailure={onFailure}
    onSuccess={(_,{email})=>successFeedback.open(email)}
  >{(form) =>
    <SignInLayout hideSupportMessage system={system}>
      <Card>
        <CardHeader>
          <h1><FormattedMessage
            defaultMessage="User password recovery"
            description="Password recovery title"
          /></h1>
          <p><FormattedMessage
            defaultMessage="Fill in below and a recovery link will be sent to your email"
            description="Password recovery instructions"
          /></p>
        </CardHeader>
        <form onSubmit={form.utils.handleSubmit}>
          <TextInput
            path="username"
            label={intl.formatMessage({
              defaultMessage: "Username:",
              description: "Password recovery username input label"
            })}
            placeholder={intl.formatMessage({
              defaultMessage: "Enter your username",
              description: "Password recovery username input placeholder"
            })}
          />
          <Errors form={form}/>
          {
            successFeedback.isOpen &&
            <ul className="successContainer">
              <li>
                <span><FormattedMessage
                  defaultMessage="Recovery link sent successfully to your email (<b>{email}</b>)."
                  description="Password recovery success message"
                  values={{
                    b: chunks => <b>{chunks}</b>,
                    email: successFeedback.isOpen
                  }}
                /></span>
                <span className="icon icon-x" onClick={() => { successFeedback.close(); form.utils.setValue('username','') }}/>
              </li>
            </ul>
          }
          <CardActionButton type="submit" disabled={successFeedback.isOpen} isLoading={form.isLoading} highlight><FormattedMessage
            defaultMessage="Recover password"
            description="Password recovery submit button"
          /></CardActionButton>
          <LinkContainer><Link className="link" skipTo="/" small><FormattedMessage
            defaultMessage="Back to sign in"
            description="Password recovery back to sign in link"
          /></Link></LinkContainer>
        </form>
      </Card>
      <SupportMessage system={system}/>
    </SignInLayout>
  }
  </WithForm>
))
