import React from 'react'
import styled from 'styled-components'
import {SignInLayout, Card, CardHeader, CardActionButton} from '../components/SigninLayout'
import {Icon} from 'startlibs/lib/components'
import {FormattedMessage} from "react-intl";

const UserNotFoundPage = styled(SignInLayout) `
  color: rgba(0,0,0,0.5);
  h2 {
    margin-bottom: 0.5rem;
    color: rgba(0,0,0,0.7);
    font-size: 22px;
  }
  ${Icon} {
    font-size: 90px;
    margin-bottom: 0.75rem;
    display: block;
  }
`

export class UserNotFound extends React.PureComponent {

  render() {
    const {system} = this.props
    return <UserNotFoundPage system={system}>
      <Card centralizedContent>
          <Icon icon="warning"/>
          <h2><FormattedMessage
            defaultMessage="User not found"
            description="UserNotFound page title"
          /></h2>
          <p><FormattedMessage
            defaultMessage="Please contact support."
            description="UserNotFound page message"
          /></p>
      </Card>
    </UserNotFoundPage>
  }

}
