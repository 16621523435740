import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import { withToggles } from 'startlibs/lib/hocs'
import { Errors, Field, Form, SimpleCheckbox, TextInput, withForm } from 'startlibs/lib/form'
import { getFetcher, postFetcher } from 'startlibs'
import connect from 'react-redux/es/connect/connect'
import { formFetchWithAuthorization, signIn } from '../reducers'
import {buildValidation, confirmEmails, confirmPasswords, getErrorForField, responseFailure} from '../lib/validation'
import { Button, SplitColumnsContainer } from 'startlibs/lib/components'
import {
  SignInLayout,
  Card,
  CardHeader,
  FieldRequirements,
  BelowFieldDescription,
  AdditionalInfo,
  CardActionButton
} from '../components/SigninLayout'
import { loginFailure } from './LoginForm'
import { PasswordInput } from '../components/PasswordInput'
import { ActivationSuccess } from './ActivationSuccess'
import {FormattedMessage, injectIntl} from "react-intl";
import {allRequired} from "./JwtRegistrationForm";

const TextInputMaybeLocked = ({ value, form, path, ...rest }) =>
  value
    ? <TextInput
      {...rest}
      value={value}
      disabled
      locked
    />
    : <TextInput
      {...rest}
      tabIndex={1}
      form={form}
      path={path}
    />

@injectIntl
@withRouter
@withToggles('success', 'loading', 'visiblePassword', 'activated')
@withForm(formFetchWithAuthorization)
@connect(undefined, { signIn })
export class RegistrationForm extends React.Component {

  onFailure = responseFailure(
    ({ detail, type, message, fieldErrors }) =>
      ((message === 'error.validation' || message === 'error.http.401') && Array.isArray(fieldErrors) && fieldErrors.reduce((acc, { field }) => ({ ...acc, ...getErrorForField(field) }), {})) ||
      (type === 'https://pas.purview.net/problem/invalid-password' && { password: [] }) ||
      (message === 'error.emailexists' && { email: [this.props.intl.formatMessage({
          defaultMessage: 'This email is already registered',
          description: 'Registration error message for already registered email'
        })] })
  )

  onSuccess = (_, { id_token }) => {
    if (id_token) {
      this.props.activated.open(id_token)
    } else {
      localStorage.setItem('hasLogged', 'true')
      const url = this.props.originalLocation
      this.props.success.open()
    }
  }


  handleSubmit = (e) => {
    e.preventDefault()
    if (this.props.newRegistrationForUsername) {
      this.props.loading.open()
      postFetcher('/pasapi/authenticate', {
        username: this.props.newRegistrationForUsername,
        password: this.props.form.properties.currentPassword,
        systemId: this.props.system.systemId,
        rememberMe: false
      })
        .then(({ idToken }) => {
          this.props.form.utils.submitProperties(this.props.form.properties, { id_token: idToken })
          this.props.loading.close()
        })
        .catch(({ errorResponse }) => {
          this.props.form.utils.setErrors(loginFailure(this.props.intl)(errorResponse, this.props.intl.formatMessage({
            defaultMessage: "The current password is incorrect.",
            description: "Current password error message"
          })))
          this.props.loading.close()
        })
    } else {
      this.props.form.utils.submitProperties()
    }
  }

  componentDidMount() {
    if (this.props.registrationValues) {
      this.props.form.utils.setValues((chain) => chain.assign({ ...this.props.registrationValues}))
    }
    if (this.props.userEmail) {
      this.props.form.utils.setValues((chain) => chain.assign({ email: this.props.userEmail}))
    }
    if (this.props.userFirstName) {
      this.props.form.utils.setValues((chain) => chain.assign({ firstName: this.props.userFirstName }))
    }
    if (this.props.userLastName) {
      this.props.form.utils.setValues((chain) => chain.assign({ lastName: this.props.userLastName }))
    }
  }

  render() {
    const {intl, system, activated, form, success, idToken, visiblePassword, newRegistration, newRegistrationForUsername, userEmail, userFirstName, userLastName, loading, registrationValues } = this.props
    const { username, email, firstName, lastName } = registrationValues || {}
    const signupFromOtherMeans = username && !!email
    const preValidation = buildValidation({
      password: [allRequired(intl), confirmPasswords(intl, 'newPassword')],
      firstName: [allRequired(intl)],
      lastName: [allRequired(intl)],
      newPassword: [allRequired(intl)],
      email: [allRequired(intl)],
      ...(this.props.newRegistrationForUsername ? { currentPassword: [allRequired(intl)] } : {})
    })
    if (activated.isOpen) {
      return <ActivationSuccess token={activated.isOpen} system={system} newRegistration />
    }
    return <SignInLayout system={system}>
      {!success.isOpen ?
        <Card>
          <CardHeader>
            <h1>{signupFromOtherMeans
              ? intl.formatMessage({defaultMessage:"Registration",description:"Registration title, signup from other means"})
              : newRegistration
                ? intl.formatMessage({defaultMessage:'User registration',description:'User registration title'})
                : intl.formatMessage({defaultMessage:'User registration update',description:'User registration update title'})
              }</h1>
            {
              newRegistration
                ? <p><FormattedMessage
                  defaultMessage="Please fill in the fields below to register your account."
                  description="Registration instructions"
                /></p>
                : <p><FormattedMessage
                  defaultMessage="Please fill in the fields below to enable <b>enhanced authentication security</b> for your account."
                description="Registration update instructions"
                values={{
                  b: chunks => <b>{chunks}</b>
                }}
                /></p>
            }
          </CardHeader>
          <Form
            alwaysSave
            preValidation={preValidation}
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            onSubmit={this.handleSubmit}
            form={form}
            id_token={idToken}
            url='/pasapi/register'
          >
            <SplitColumnsContainer>
              <TextInput
                label={intl.formatMessage({
                  defaultMessage: "Username",
                  description: "Registration username field label"
                })}
                value={username || newRegistrationForUsername}
                locked
                disabled
              />
              <TextInputMaybeLocked
                value={email || userEmail}
                tabIndex={1}
                label={intl.formatMessage({
                  defaultMessage: "Email",
                  description: "Registration email field label"
                })}
                form={form}
                path="email"
              />
            </SplitColumnsContainer>
            <SplitColumnsContainer>
              <TextInput
                tabIndex={1}
                label={intl.formatMessage({
                  defaultMessage: "First name",
                  description: "Registration first name field label"
                })}
                form={form}
                path="firstName"
              />
              <TextInput
                tabIndex={1}
                label={intl.formatMessage({
                  defaultMessage: "Last name",
                  description: "Registration last name field label"
                })}
                form={form}
                path="lastName"
              />
            </SplitColumnsContainer>
            {
              newRegistrationForUsername &&
              <PasswordInput
                tabIndex={1}
                form={form}
                path="currentPassword"
                label={intl.formatMessage({
                  defaultMessage: "Current password",
                  description: "Registration current password field label"
                })}
              />
            }
            <SplitColumnsContainer>
              <Field label={signupFromOtherMeans ?
                intl.formatMessage({
                  defaultMessage: "Create password",
                  description: "Registration password field label"
                }): intl.formatMessage({
                    defaultMessage:"New password",
                  description:"Registration new password field label"
                  })}>
                <PasswordInput
                  tabIndex={1}
                  form={form}
                  path="password"
                  syncVisible={visiblePassword}
                  withoutField
                />
              </Field>
              <Field label={intl.formatMessage({
                  defaultMessage: "Confirm new password",
                description: "Registration confirm password field label"
                })}>
                <PasswordInput
                  tabIndex={1}
                  onPaste={(e) => e.preventDefault()}
                  form={form}
                  path="newPassword"
                  syncVisible={visiblePassword}
                  withoutField
                />
              </Field>
            </SplitColumnsContainer>
            <FieldRequirements hasErrors={!!form.errors['password']}>
              <FormattedMessage
                defaultMessage="Your new password must be <b>{pwdMinimumLength} or more characters</b>, containing at least <b>one uppercase letter</b>, one <b>number</b> and one <b>special character</b>"
                description="Password requirements"
                values={{
                  pwdMinimumLength: system.systemAuthPolicy.pwdMinimumLength,
                  b: msg => <b>{msg}</b>
                }}
              />
            </FieldRequirements>
            <Errors form={form} />
            <CardActionButton
              isLoading={form.isLoading || loading.isOpen}
              className="highlight with-loader"
              tabIndex={1}
              type="submit"
              highlight
            >
              {newRegistration ? intl.formatMessage({
                defaultMessage: "Register",
                description: "Registration submit button"
              }): intl.formatMessage({
                defaultMessage:"Save",
                description: "Registration update submit button"
              })}
            </CardActionButton>
          </Form>
        </Card> :
        <Card>
          <CardHeader>
            <h1><FormattedMessage
              defaultMessage="Almost there! Check your email"
              description="Registration success title"
            /></h1>
          </CardHeader>
          {/* <FormattedMessage
            defaultMessage={`<p>We have e-mailed you a link to activate your account.</p>
          <p>Please check <b>{email}</b> and click the activation link to finish your registration
            update.</p>
          <AdditionalInfo>If you do not find the email in your inbox, please check your spam or junk
            folder.</AdditionalInfo>`}
            description="Registration success message"
            values={{
              p: chunks => <p>{chunks}</p>,
              AdditionalInfo: chunks => <AdditionalInfo>{chunks}</AdditionalInfo>,
              email: form.properties.email
            }}
          /> */}
          <FormattedMessage
            defaultMessage={`<p>We have e-mailed you a link to activate your account.</p> 
              <p>Please check <b>{email}</b> and click the activation link to finish your registration update.</p>
              <info>If you do not find the email in your inbox, please check your spam or junk folder.</info>`}
            description="Registration success message"
            values={{
              email: form.properties.email,
              p: chunks => <p>{chunks}</p>,
              b: chunks => <b>{chunks}</b>,
              info: chunks => <AdditionalInfo>{chunks}</AdditionalInfo>,
            }}
          />
        </Card>}
    </SignInLayout>
  }
}
