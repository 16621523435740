import { Button } from 'startlibs/lib/components';
import { Link, withRouter } from 'react-router-dom';
import {connect} from 'react-redux'
import { getFetcher, formFetch } from 'startlibs';
import {
  withForm,
  Errors,
  Field,
  Form,
  TextInput,
  SimpleCheckbox
} from 'startlibs/lib/form';
import {withToggles} from 'startlibs/lib/hocs'
import React from 'react'
import jwt_decode from 'jwt-decode'
import styled from 'styled-components'
import {injectIntl} from "react-intl";
import {
  AdditionalActionButtons,
  Card,
  CardActionButton,
  CardHeader,
  LinkSSO,
  SignInLayout,
  SignInMessageBox
} from '../components/SigninLayout';
import {JwtAuthentication} from './JwtAuthentication'
import {PasswordInput} from '../components/PasswordInput'
import {RedefinePasswordForm} from './RedefinePassword'
import {SupportMessage} from '../components/SupportMessage'
import {TwoFactorAuthentication} from './TwoFactorAuthentication'
import {buildValidation, required, responseFailure} from '../lib/validation'
import {signIn} from '../reducers'
import {FormattedMessage} from "react-intl";
import {LocaleSelector} from "../components/LocaleSelector";

const DEFAULT_FORM = {rememberMe: false}

const SignInCard = styled(Card)`
  max-width: ${props => props.accountSettings ? `39rem` : `35rem`};
  margin: auto;
  transition: 0.25s ease;
`

const UNASSOCIATED_SYSTEM_ERROR = 'User user is not associated with a system.'
const INVALID_CREDENTIALS = (intl) => intl.formatMessage({
  defaultMessage: 'Invalid credentials',
  description: 'Login error message when credentials are invalid'
})

export const loginFailure = (intl) => ({detail, message, params, status}, passwordError = INVALID_CREDENTIALS(intl)) =>
  (status === 401 && detail === UNASSOCIATED_SYSTEM_ERROR && {'': [passwordError]}) ||
  (message === 'error.suspended' && {'': [intl.formatMessage({
      defaultMessage: "This account has been suspended. Please contact your administrator.",
      description: "Login error message when account is suspended"
    })]}) ||
  (message === 'error.locked' && {'': [<span><FormattedMessage
      defaultMessage="This account has been locked. Please check {hasEmail, select, true {<b>{email}</b>} other {your email}} and click the link to unlock."
      description="Login error message when account is locked"
      values={{
        email: params.email,
        hasEmail: !!params.email,
        b: (...chunks) => <b>{chunks}</b>
      }}
    /></span>]}) ||
  (message === 'error.notActivated' && {'': [<span>
      <FormattedMessage
        defaultMessage="<b>Account not activated yet</b>. We just sent a new activation link to {hasEmail, select, true {<b>{email}</b>} other {your email}}. <detail>Please check your spam folder if necessary.</detail>"
        description="Login error message when account is not activated"
        values={{
          email: params.email,
          hasEmail: !!params.email,
          detail: (...chunks) => <span className="error-detail">{chunks}</span>,
          b: (...chunks) => <b>{chunks}</b>
        }}
      /></span>]}) ||
  ((message === 'error.validation' || message === 'error.http.401') && {'currentPassword': [passwordError]})


@injectIntl
@withRouter
@withToggles('loading', 'accountSettings', 'expiredPassword', 'verify2fa')
@withForm(formFetch)
@connect(undefined, {signIn})
export class LoginForm extends React.Component {

  searchParams = new URLSearchParams(this.props.location.search)
  jwt = this.searchParams.has("jwt") ? jwt_decode(this.searchParams.get("jwt")) : {}

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 403) {
      this.passwordExpired(response.params.key)
    } else {
      // If the SSO option is enabled, when the email is not @purview, it will redirect to the SSO login page
      if (response.url) {
        return window.location = response.url
      }
      responseFailure(loginFailure(this.props.intl))(...args)
    }
  }

  passwordExpired = (key) => {
    this.props.verify2fa.close()
    this.props.expiredPassword.open(key)
  }

  onSuccess = (_, {idToken,id_token,username,preAuthToken}) => {
    if (username) {
      this.props.needsRegistration(idToken,username)
    } else if (preAuthToken) {
      this.props.verify2fa.open(preAuthToken)
    } else {
      this.concludeLogin(id_token)
    }
  }

  concludeLogin = (id_token) => {
    if (this.props.accountSettings.isOpen) {
      this.props.loading.open()
      getFetcher('/pasapi/account/info', null, {headers: new Headers({'Authorization': `Bearer ${id_token}`})})
        .then((result) => {
          this.props.signIn({...result, id_token})
          this.props.history.push('/account?systemId='+this.props.system.systemId)
        })
    } else {
      this.props.loading.open()
      window.location = this.props.system.systemWebHooks.systemAuthWebHook + id_token
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search)
    // if(this.props.system.systemWebHooks && this.props.system.systemWebHooks.migratedUrl){
    //   window.location.href = this.props.system.systemWebHooks.migratedUrl+this.props.location.search;
    // }
    if (urlParams.get("loginFailure")==='true' || urlParams.get('newRegistration')) {
      this.props.form.utils.setErrors({"password":[INVALID_CREDENTIALS(this.props.intl)]})
    }
    if (urlParams.get("username")) {
      this.props.form.utils.setValue('username',urlParams.get("username"))
    }
    if (urlParams.get("preAuthToken")) {
      this.props.verify2fa.open(urlParams.get("preAuthToken"))
    }
    this.props.form.utils.setValue('systemId',this.props.system.systemId)
    if (this.props.loginToSettings) {
      this.props.accountSettings.open()
    }
  }



  render() {
    const {intl, system, form, loading,fromActivation, accountSettings, expiredPassword, loginToSettings, verify2fa, needsRegistration, location, autoLogin} = this.props
    const expiredSession = location.state && location.state.expiredSession
    const preValidation = buildValidation({
      password: [required(intl)],
      username: [required(intl)]
    },(props) => ({...props,systemId:this.props.system.systemId}))

    if (verify2fa.isOpen) {
      return <TwoFactorAuthentication
        system={system}
        preAuthToken={verify2fa.isOpen}
        concludeLogin={this.concludeLogin}
        passwordExpired={this.passwordExpired}
        username={form.properties.username}
      />
    }

    if (this.jwt.sub && !expiredPassword.isOpen) {
      return <JwtAuthentication
        passwordExpired={this.passwordExpired}
        needsRegistration={needsRegistration}
        system={system}
        verify2fa={verify2fa}
        parsedJwt={this.jwt}
        jwt={this.searchParams.get("jwt")}
        autoLogin={autoLogin}
      />
    }

    const ssos = system.ssoEntities || []

    return (
      <SignInLayout hideSupportMessage system={system}>
        {
          fromActivation &&
          <SignInMessageBox>
            <FormattedMessage
              defaultMessage="Email address successfully updated."
              description="Login form, message displayed after email address update"
            />
          </SignInMessageBox>
        }
        {
          expiredSession &&
          <SignInMessageBox warning>
            <FormattedMessage
              defaultMessage="Your session has expired. Please sign in again."
              description="Login form, message displayed after session expiration"
            />
          </SignInMessageBox>
        }
        {!expiredPassword.isOpen ?
          <SignInCard accountSettings={accountSettings.isOpen}>
            {accountSettings.isOpen ?
              <CardHeader>
                <h1><FormattedMessage
                  defaultMessage="User authentication settings"
                  description="Login form, authentication settings header"
                /></h1>
                <p><FormattedMessage
                  defaultMessage="Enter your credentials to manage your account"
                  description="Login form, authentication settings instructions"
                /></p>
              </CardHeader>
              :
              <CardHeader>
                <h1><FormattedMessage
                  defaultMessage="Sign in to {systemName}"
                  description="Login form header"
                  values={{
                    systemName: system.systemName
                  }}
                /></h1>
                <p><FormattedMessage
                  defaultMessage="Enter your details below"
                  description="Login form header"
                /></p>
              </CardHeader>
            }
            <Form
              alwaysSave
              defaultProperties={DEFAULT_FORM}
              preValidation={preValidation}
              onSuccess={this.onSuccess}
              onFailure={this.onFailure}
              form={form}
              url='/pasapi/authenticate'
            >
              <TextInput
                autoComplete="off"
                tabIndex={1}
                label={system.labels.loginInput || intl.formatMessage({
                  defaultMessage:"Username:",
                  description:"Login form, username field label"
                })}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter your username",
                  description: "Login form, username field placeholder"
                })}
                form={form}
                path="username"
              />
              <Field label={intl.formatMessage({
                  defaultMessage: "Password:",
                  description: "Login form, password field label"
                })}>
                <Link className={(Object.keys(form.errors).length ? 'has-error ' : '') + 'password-link'} to="/recovery"
                      tabIndex={2}><FormattedMessage
                  defaultMessage="Forgot password?"
                  description="Login form, forgot password link"
                /></Link>
                <PasswordInput
                  autoComplete="off"
                  tabIndex={1}
                  form={form}
                  withoutField
                  path="password"
                  placeholder={intl.formatMessage({
                    defaultMessage: "Enter your password",
                    description: "Login form, password field placeholder"
                  })}
                />
              </Field>
              {false && !accountSettings.isOpen &&
              <div className="checkbox-list">
                <SimpleCheckbox
                  form={form}
                  label="Keep me signed in while in this computer"
                  path="rememberMe"
                />
              </div>}
              <Errors form={form}/>
              <CardActionButton highlight isLoading={form.isLoading || loading.isOpen} tabIndex={1}
                                type="submit">{accountSettings.isOpen
                ? intl.formatMessage({
                  defaultMessage: 'Sign in to settings',
                  description: 'Login form, sign in to settings button'
                })
                : intl.formatMessage({
                  defaultMessage:'Sign in',
                  description:'Login form, sign in button'
                })}</CardActionButton>
              {ssos.map(sso => <LinkSSO href={sso.url}>{sso.label || intl.formatMessage({
                defaultMessage:"Use single sign-on (SSO) instead",
                description:"Login form, SSO link"
              })}</LinkSSO>)}
            </Form>
          </SignInCard> :
          <ExpiredPassword system={system} tokenKey={expiredPassword.isOpen}/>
        }
        <SupportMessage system={system}/>
        {!expiredPassword.isOpen && !loginToSettings &&
        <AdditionalActionButtons>
          <Button outline small onClick={accountSettings.toggle}>{accountSettings.isOpen ?
            <span><FormattedMessage
              defaultMessage="Back to sign in"
              description="Login form, back to sign in button"
            /></span> : intl.formatMessage({
              defaultMessage:'Authentication settings',
              description:'Login form, authentication settings button'
            })}</Button>
        </AdditionalActionButtons>
        }
      </SignInLayout>
    )
  }
}


const ExpiredPassword = (props) => <RedefinePasswordForm
  {...props}
  header={() =>
    <CardHeader>
      <h1><FormattedMessage
        defaultMessage="Your password has expired"
        description="Expired password form header"
      /></h1>
      <p><FormattedMessage
        defaultMessage="Please set a new password to continue"
        description="Expired password form instructions"
      /></p>
    </CardHeader>
  }
/>
