import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from 'startlibs/lib/components'
import {withForm, Errors, Field, Form, TextInput, SimpleCheckbox, WithForm} from 'startlibs/lib/form'
import {getFetcher, formFetch} from 'startlibs'
import {withToggles} from 'startlibs/lib/hocs'
import {bearerHeader, logout, signIn, verify2FA} from '../reducers'
import {buildValidation, required, responseFailure} from '../lib/validation'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {ice} from 'icepath'
import styled from 'styled-components'
import {
  SignInLayout,
  Card,
  CardHeader,
  AdditionalActionButtons,
  CardActionButton,
  SignInMessageBox
} from '../components/SigninLayout'
import {RedefinePasswordForm} from './RedefinePassword'
import {getColor} from 'startlibs/lib/lib'
import {FormattedMessage, injectIntl} from "react-intl";

const SignInCard = styled(Card)`
  max-width: 35rem;
  margin: auto;
`

const ResendCodeMessage = styled.div`
  color: rgba(0,0,0,0.5);
  margin-top: 1rem;
  p {
    margin-bottom: 0.25rem;
  }
  .link {
    font-weight: 600;
  }
  .detail {
    font-size: 12px;
  }
  .loading {
    color: ${getColor('main')};
    font-weight: 600;
  }
  .success {
    color: ${getColor('success')};
  }
`

const ButtonsContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`

@injectIntl
@withRouter
@withToggles('loading', 'resentCode', 'resendingCode')
@withForm(verify2FA)
@connect(undefined,{logout})
export class TwoFactorAuthentication extends React.Component {

  onFailure = (...args) => {
    const {response} = args[1]
    if (response.status === 403) {
      this.props.passwordExpired(response.params.key)
    } else if (response.status === 401) {
      this.logout(true)
    } else {
      responseFailure(
        ({detail, message, status}) =>
          ((status === 400) && {'oneTimePassword': [this.prop.intl.formatMessage({
              defaultMessage: `Invalid or expired code.`,
              description: `2FA error message`
            })]})
      )(...args)
    }
  }

  logout = (expired) => {
    this.props.logout()
    this.props.history.push(`/?systemId=${this.props.system.systemId}&username=${this.props.username}`,{loginKey:Date.now(),expiredSession:expired})
  }

  onSuccess = (_, {id_token}) => {
    this.props.loading.open()
    this.props.concludeLogin(id_token)
  }


  resend = () => {
    this.props.resentCode.close()
    this.props.resendingCode.open()
    getFetcher('/pasapi/generate-2fa-code', undefined, bearerHeader(this.props.preAuthToken))
      .then(() => {
        this.props.resentCode.open()
        this.props.resentCode.closeAfter(30000)()
        this.props.resendingCode.close()
      })
  }

  render() {
    const {intl, system, form, loading, preAuthToken, resendingCode, resentCode} = this.props

    const preValidation = buildValidation({
      oneTimePassword: [required(intl)]
    })
    return (
      <SignInLayout system={system}>
        <SignInCard>
          <CardHeader>
            <h1><FormattedMessage
              defaultMessage="Two-Factor Authentication"
              description="2FA title"
            /></h1>
            <p><FormattedMessage
              defaultMessage="A temporary code was just sent to your e-mail."
              description="2FA instructions"
            /></p>
          </CardHeader>
          <Form
            alwaysSave
            preValidation={preValidation}
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            form={form}
            id_token={preAuthToken}
          >
            <TextInput
              tabIndex={1}
              label={intl.formatMessage({
                defaultMessage: "Code",
                description: "2FA code label"
              })}
              constraint="###############"
              placeholder={intl.formatMessage({
                defaultMessage: "Enter the code here",
                description: "2FA code placeholder"
              })}
              form={form}
              path="oneTimePassword"
            />
            <Errors form={form}/>
            <ResendCodeMessage>
                {resentCode.isOpen ?
                <p className="success"><FormattedMessage
                  defaultMessage="New email successfully sent."
                  description="2FA success message"
                /></p>
                :
                <p><FormattedMessage
                  defaultMessage="Did not get the email?"
                  description="2FA resend message"
                />{resendingCode.isOpen
                  ? <b className="loading"><FormattedMessage
                    defaultMessage="Sending..."
                    description="2FA sending message"
                  /></b>
                  : <span><a className="link" onClick={this.resend}><FormattedMessage
                    defaultMessage="Resend it"
                    description="2FA resend link"
                  /></a>.</span>}</p>
                }
              <p className="detail"><FormattedMessage
                defaultMessage="Please also check your spam or junk folder if necessary."
                description="2FA spam message"
              /></p>
            </ResendCodeMessage>
            <ButtonsContainer>
              <Button tabIndex={2} onClick={() => this.logout()} type="button"><FormattedMessage
                defaultMessage="Cancel"
                description="2FA cancel button"
              /></Button>
              <Button highlight isLoading={form.isLoading || loading.isOpen} tabIndex={1} type="submit"><FormattedMessage
                defaultMessage="Next"
                description="2FA submit button"
              /></Button>
            </ButtonsContainer>
          </Form>
        </SignInCard>
      </SignInLayout>
    )
  }
}
